import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

export default function({style, filename}) {
  const data = useStaticQuery(graphql`
    query {
      md: allMarkdownRemark {
        nodes {
          html
          fields {
            parent {
              name
            }
          }
        }
      }
    }
  `)

  const md = data.md.nodes.find(node => node.fields.parent.name === filename)

  if (!md) return null

  return (
    <div
      style={style}
      dangerouslySetInnerHTML={{ __html: md.html}}
    />
  )
}
