import React from 'react'

import SEO from "../components/seo"
import HalfHero from '../components/half-hero'
import SimpleMd from "../components/simpleMd"

const Page = () => {
  return (
    <>
      <SEO title="EULA" />

      <HalfHero fullWidth />

      <SimpleMd style={{marginTop: '2rem'}}  filename="EULA" />
    
    </>
  )
}

export default Page